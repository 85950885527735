* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  position: relative;
  background-color: #0f0f0f;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  height: 100vh;
  background-image: url("./snacks-bg.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.landing-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button {
  display: flex;
  background-color: #dbdbdb;
  color: black;
  filter: drop-shadow(0px 6.48px 6.48px rgba(0, 0, 0, 0.5));
  padding: 15px 30px;
  text-decoration: none;
  border-radius: 15px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  width: 100%;
  margin-top: 2em;
}

.button-focus {
  outline: none;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(2px);
  color: white;
}

.auth-label {
  display: block;
  text-align: left;
  color: white;
  margin-left: 0.6em;
  margin-top: 1em;
}
.auth-input {
  background: #333333;
  width: 100%;
  border-radius: 15px;
  padding: 15px 30px;
  outline: none;
}

.auth-container {
  width: 20em;
  display: flex;
  flex-direction: column;
  margin-top: 4em;
}

.input-container {
  position: relative;
}
.input-container .auth-input {
  padding-left: 3rem; /* Adjust this value to control the horizontal position of the input text */
  color: white;
}
.input-container .auth-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #848484;
  /* stroke-width: 1px; */
}

.input-container .auth-icon-eye {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #848484;
  cursor: pointer;
}

.title {
  font-family: "Grifter", sans-serif;
}
