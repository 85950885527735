@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  /* disable android tap highlights */
  -webkit-tap-highlight-color: transparent;
}

root {
  --font-poppins: "Poppins", sans-serif;
  --font-inter: "Inter", sans-serif;
  --theme-color: "#fab317";
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.inter-regular {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Grifter";
  src: url("../src/static/grifterbold.otf") format("opentype");
}

.grifter-regular {
  font-family: "Grifter", sans-serif;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Product Sans";
  src: url("../src/static/productsans.ttf") format("truetype");
}

.productsans-regular {
  font-family: "Product Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
}

*:not(input):not(select):not(code):not(textarea):not([contenteditable]) {
  webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.statistics {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding-bottom: 2em;
}

.statistics-box {
  color: white;
  width: 80% !important;
  font-size: 1.5em;
  border-radius: 10px;
  padding: 1em;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.stat-ind-item {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1em;
}

.stat-ind-item .dish-card {
  width: 40%;
  height: 50px;
}
.statistics h2 {
  margin: 0 0 0.5em 0;
  font-family: "Poppins", sans-serif;
}
