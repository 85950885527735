.menu-screen-title .grifter-regular {
  font-size: 2em;
}
.menu-screen-title .poppins-regular {
  font-size: 1em;
}

.menu-screen {
  width: 100vw;
  height: 100%;
  min-height: 100%;
  padding: 1em;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
  /* i hate css for this */
  /* change the above to have scrollbar there */
}

.menu-screen-title {
  display: inline-block;
  margin-top: 6em;
}

.profile-pic {
  border-radius: 50%;
  width: 3.5em;
  float: right;
}

.search-dish {
  margin-top: 2em;
  outline: none;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(3px);
  color: white;
  border-radius: 10px;
  padding: 1em 1em;
  width: 100%;
}

.input-icon {
  position: relative;
  display: inline;
}

.input-icon .search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.input-icon {
  cursor: pointer;
}

.food-category {
  background: rgba(255, 255, 255, 0.15);

  backdrop-filter: blur(3px);
  color: white;
  padding: 0.5em 1.5em;
  text-align: center;
  border-radius: 10px;
  font-size: 0.93em;
  cursor: pointer;
}

.menu-categories {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1em;
  /* gap: 0.5em; */
}

.category-selected {
  background-color: rgba(255, 255, 255, 0.4);
}

.dish-list {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  gap: 2em;
  overflow-y: scroll;
  max-height: 100%;
  scrollbar-width: thin;
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent;
}

.dish-list::-webkit-scrollbar {
  display: none;
}
.dish-card {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(3px);
  color: white;
  border-radius: 10px;
  width: 100%;
  min-height: 130px;
  max-height: 150px;
  display: flex;
}

.dish-name {
  font-size: 1em;
  font-weight: 700;
  display: block;
}

.dish-price {
  font-size: 1.2em;
  display: block;
}

.dish-left {
  padding: 2em 2em;
  width: 40%;
  flex: 1;
}

.dish-image {
  position: absolute;
  right: 0;
  height: 100%;
  object-fit: cover;
  width: 70%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  color: black;
}

.modal-content {
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(3px);
  padding: 20px 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
  animation: slideIn 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-50px);
  }
  to {
    transform: translateY(0);
  }
}

.modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 1;
  animation: fadeIn 0.5s;
}

.modal-content h1 {
  font-size: 1.7em;
  text-align: center;
  font-weight: bolder;
}

.modal-content .submit-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content .close-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
}


.modal-data {
  display: flex;
  justify-content: space-around;
  margin-top: 1em;
}

.modal-values,
.modal-keys {
  font-size: 1.2em;
}

.modal-values {
  text-align: center;
}
.modal-dish-name {
  max-width: 5em;
  padding: 0em 0.5em;
  text-align: center;
  background-color: white;
  outline: none;
  border: 1px solid black;
}

.modal-cost,
.modal-stock {
  display: flex;
  gap: 0.5em;
}

.modal .cost-icon,
.stock-icon,
.modal-cat {
  cursor: pointer;
  margin-top: -0.2em !important;
}

.modal-values p {
  margin-top: -0.2em !important;
}
.modal-values *,
.modal-keys * {
  margin-top: 1em;
}

.modal-cost p::before {
  content: "₹";
}

.modal-cost,
.modal-stock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.modal-veg {
  margin-top: -0.1em !important;
}

.add-dish {
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(3px);
  color: white;
  border-radius: 10px;
  padding: 1em 1em;
  width: fit-content;
  cursor: pointer;
}

.killswitch {
  background-color: #f42c39;
  color: white;
  border-radius: 10px;
  padding: 1em 1em;
  width: fit-content;
  cursor: pointer;
  font-size: 0.5em;
  height: fit-content;
  margin-top: 1em;
  position: absolute;
  top: 1em;
  right: 1em;
}
