.order-card {
  width: 90%;
  overflow-x: scroll;
  max-height: unset;
}

.order-card .order-left {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex: 0.7;
  padding: 1em;
  text-align: center;
  flex-direction: column;
  border-right: 1px solid #f0f0f0;
}

.order-card .user-name {
  font-size: 1em;
  font-weight: 600;
}

.order-card .order-email {
  font-size: 0.8em;
  color: #777;
}

.order-right {
  flex: 1;
  padding: 1em;
  text-align: center;
}

.order-right * {
  font-weight: 600 !important;
}

space {
  margin-right: 1em;
}

.filter-button {
  background-color: var(--background-light);
  backdrop-filter: blur(3px);
  border-radius: 20px;
  padding: 0.5em 1em;
  color: white;
}

.filter-button-active {
  transform: scale(1.05);
  border: 1.5px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.4);
}

.filter-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 1em;
  width: 100%;
}
