.view-users-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 2em;
  padding: 2em 0em;
}

.just-card {
  color: white;
  border-radius: 10px;
  width: 80%;
  min-height: 130px;
  max-height: 150px;
  display: flex;
  background-color: var(--background-light);
  backdrop-filter: blur(3px);
}

.just-card img {
  border-radius: 50%;
  width: 100px;
  margin: 1em;
}

.just-card .order-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5em;
}

.user-search .search-dish {
  margin-top: 0 !important;
  width: 100%;
}

.user-search {
  width: 80%;
}

.itsamerchant {
  position: absolute;
  top: 0.5em;
  right: 0.3em;
  background-color: #ef233c;
  padding: 3px 5px;
  border-radius: 40px;
  font-size: 0.8em;
}
