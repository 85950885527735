.scan-again {
  color: white;
}

.square-video {
  width: 350px;  /* or any size you want */
  height: 350px; /* make sure this is the same as the width */
  object-fit: cover;
}

.scan-again {
  transition: transform 0.1s ease, border 0.1s ease, box-shadow 0.1s ease;
  cursor: pointer;
  text-align: center;
}
.scan-again {
  display: flex;
  align-items: center;
  justify-content: center;
}
.scan-again:active {
  transform: scale(1.05);
  border: 2.5px solid rgba(255, 255, 255, 0.5);
  box-shadow: 1px 1px 45px 3px rgba(255, 255, 255, 0.25);
}
.scan-again {
  color: white;
  border-radius: 10px;
  width: fit-content;
  background-color: var(--background-light);
  backdrop-filter: blur(3px);
  margin-top: 1.5em;
  padding: 1em 3em;
  margin: auto;
  margin-top: 3em;
}

.scan-again span {
  font-size: 1.3em;
  margin-left: 1em;
}
.qrData-container {
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
}
.qrData-container h1 {
  font-size: 1.5em;
  margin-bottom: 1em;
  letter-spacing: 1px;
}

.total-amount::before {
  content: "Total:  ";
  font-family: "Product Sans", sans-serif;
}
