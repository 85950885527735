:root {
  --foreground-light: rgba(255, 255, 255, 0.4);
  --background-light: rgba(255, 255, 255, 0.15);
}

.menu-screen-title .grifter-regular {
  font-size: 2em;
}
.menu-screen-title .poppins-regular {
  font-size: 1em;
}

.menu-screen {
  width: 100vw;
  height: 100%;
  min-height: 100%;
  padding: 1em;
  box-sizing: border-box;
}

.ticketScanner {
  margin-top: 2em !important;
}

.portal-card {
  color: white;
  border-radius: 10px;
  width: 100%;
  min-height: 130px;
  max-height: 150px;
  display: flex;
  background-color: var(--background-light);
  backdrop-filter: blur(3px);
  margin-top: 1.5em;
}

.portal-card span {
  font-size: 1.5em;
  text-align: center;
  width: 70%;
  font-weight: 700;
  display: block;
  padding: 2em 1em;
}

.portal-card img {
  position: absolute;
  right: 0;
  height: 100%;
  object-fit: cover;
  width: 40%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.portal-sub-card {
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: space-between;
  align-items: center;
}

.portal-sub-card .portal-card {
  /* width: 30%; */
  max-height: 80px;
}

.portal-sub-card .portal-card img {
  width: 40%;
}

.portal-sub-card .portal-card span {
  padding: 1.5em 1.5em;
  font-size: 1.3em;
}

.menu-screen-title {
  display: inline-block;
  margin-top: 6em;
}
.available-container {
  display: flex;
  flex-wrap: wrap;
  gap:15px;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  transition: height 0.5s ease;
}

.count-display {
  color: white;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 5em;
  height: fit-content;
}

.available-item-name {
  display: block;
  width: 100%;
}
.count-display * {
  font-size: 1.1em;
  line-height: 1.1em;
}

.available-item-count {
  display: block;
  width: 100%;
  font-size: 3em;
}

.expand-items {
  outline: none;
  background-color: var(--background-light);
  backdrop-filter: blur(3px);
  color: white;
  border-radius: 10px;
  padding: 1em 1em;
  width: 100%;
  text-align: center;
  height: fit-content;
  cursor: pointer;
}
.expand-items .dropdown-icon {
  color: var(--foreground-light);
  display: inline-block;
  margin-left: 0.5em;
  margin-bottom: 0.2em;
}

.portal-card {
  transition: transform 0.1s ease, border 0.1s ease, box-shadow 0.1s ease;
  cursor: pointer;
}

.portal-card:active {
  transform: scale(1.05);
  border: 2.5px solid rgba(255, 255, 255, 0.5);
  box-shadow: 1px 1px 45px 3px rgba(255, 255, 255, 0.25);
}
